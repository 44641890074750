var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalOpenContestation",
        lazy: "",
        "no-fade": "",
        "no-enforce-focus": "",
        centered: "",
        size: "md",
        title: _vm.$t("payments.titles.openContestation"),
        "ok-title": _vm.$t("payments.buttons.confirmOpenContestation"),
        "ok-disabled": !_vm.enableOkButton,
        "cancel-title": _vm.$t("payments.buttons.cancel"),
      },
      on: { ok: _vm.confirm, cancel: _vm.cancel, hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _c("b-container", { staticClass: "p-0" }, [
        _c(
          "div",
          { attrs: { slot: "modal-body" }, slot: "modal-body" },
          [
            _c("b-form-textarea", {
              attrs: {
                id: "contestationReason",
                placeholder: _vm.$t(
                  "payments.inputs.placeholder.contestationReason"
                ),
                type: "text",
                rows: 2,
                "max-rows": 4,
                maxlength: 500,
              },
              model: {
                value: _vm.contestationReason,
                callback: function ($$v) {
                  _vm.contestationReason = $$v
                },
                expression: "contestationReason",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }