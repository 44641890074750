<template>
  <b-modal
    id="modalOpenContestation"
    v-model="cpHandlerModal"
    lazy
    no-fade
    no-enforce-focus
    centered
    size="md"
    :title="$t('payments.titles.openContestation')"
    :ok-title="$t('payments.buttons.confirmOpenContestation')"
    :ok-disabled="!enableOkButton"
    :cancel-title="$t('payments.buttons.cancel')"
    @ok="confirm"
    @cancel="cancel"
    @hidden="handleClose"
  >
    <b-container class="p-0">
      <div slot="modal-body">
        <b-form-textarea id="contestationReason" v-model="contestationReason" :placeholder="$t('payments.inputs.placeholder.contestationReason')" type="text" :rows="2" :max-rows="4" :maxlength="500" />
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import OPEN_CONTESTATION from '@graphql/payment/mutations/open-contestation.gql';

export default {
  name: 'ModalOpenContestation',
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  props: {
    handlerModal: {
      type: Boolean,
    },
    transaction: {
      type: Object,
    },
  },
  data() {
    return { enableConfirm: true, contestationReason: '' };
  },
  computed: {
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      },
    },
    enableOkButton: function () {
      return this.contestationReason.length > 2;
    },
  },
  methods: {
    confirm() {
        this.openContestation()
        .then(() => {
          this.$emit('successOpenContestation');
          this.$emit('closeModalOpenContestation');
        })
        .catch(() => {
          this.$emit('errorOpenContestation');
        });
    },
    cancel() {
      this.contestationReason = '';
    },
    handleClose() {
      this.contestationReason = '';
      this.$emit('change', false);
    },
    openContestation() {
      return this.$apollo.mutate({
        mutation: OPEN_CONTESTATION,
        variables: {
          input: {
            driver_id: this.transaction.driver,
            transaction_id: this.transaction.id,
            invoice_id: this.transaction.invoice,
            opening_comments: this.contestationReason,
          },
        },
      });
    },
  },
};
</script>
